import { updateBannerStatusApiCall } from "../../../../../utilities/api-calls/BannersApiCalls";
import { handleBannerErrors } from "./bannerErrorHandler";

export const handleUpdateBannerStatus = (bannerID, payload, setShouldBannersUpdate) => {
  const handleUpdateBannerStatusResponse = (res) => {
    if (res) {
      setShouldBannersUpdate(true);
    }
  };

  const handleUpdateBannerStatusCallback = (res, err) => {
    handleBannerErrors(err);
    handleUpdateBannerStatusResponse(res);
  };
  updateBannerStatusApiCall(bannerID, payload, handleUpdateBannerStatusCallback);
};
