import * as types from "../constants";

const initialState = {
  dbError: [],
  dbOops: false,
};

export const dbErrorResponseReducer = (state = initialState, action = {}) => {
  let response;

  switch (action.type) {
    case types.DB_ERROR_POPUP_REQUEST_DISPATCHED:
      response = {
        message: action?.payload?.message,
        details: action?.payload?.details,
        isError: true,
        actionButtonText: "OK",
      };
      return {
        ...state,
        dbError: [...state.dbError, { ...response }],
      };
    case types.DB_ERROR_POPUP_REQUEST_RESET:
      return {
        ...state,
        dbError: [],
      };
    case types.DB_OOPS_POPUP_REQUEST_DISPATCHED:
      return {
        ...state,
        dbOops: true,
      };
    case types.DB_OOPS_POPUP_REQUEST_RESET:
      return {
        ...state,
        dbOops: false,
      };

    default:
      return state;
  }
};
