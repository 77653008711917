import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { errorResponseReducer } from "../reducers/error_handling";
import { spinnerReducer } from "../reducers/loading-spinner";
import { dbErrorResponseReducer } from "../reducers/db_error_handling";

let middleware = [];

if (process.env.NODE_ENV === "development") {
  middleware = [thunk]; //logger
} else {
  middleware = [thunk];
}

const reducer = combineReducers({
  errorResponse: errorResponseReducer,
  spinner: spinnerReducer,
  dbErrorResponse: dbErrorResponseReducer,
});

const store = createStore(reducer, compose(applyMiddleware(...middleware)));

export default store;
