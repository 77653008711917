import * as types from "../constants";

const initialState = {
  showSpinner: 0,
};

export const spinnerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LOADING_SPINNER_SHOW:
      return {
        showSpinner: state.showSpinner + 1,
      };
    case types.LOADING_SPINNER_HIDE:
      return {
        showSpinner: state.showSpinner - 1,
      };
    default:
      return state;
  }
};
