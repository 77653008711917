import { BannersApiErrorMap } from "../../../../../utilities/api-calls/api-error-maps/BannersApiErrorMap";
import { showErrorFromResponse } from "../../../../../utilities/error-handlers/handle-db-error";
import { isValidationError, getUserFriendlyErrorMessageFromErrorCode } from "@db_ui/axios";

export const handleBannerErrors = (error, setApiErrorMessage) => {
  if (!error) return;
  if (error && error.wasHandledByAxiosErrorHandler) return;
  if (!isValidationError(error) && !error.wasHandledByAxiosErrorHandler) {
    showErrorFromResponse(
      getUserFriendlyErrorMessageFromErrorCode(error?.response?.data?.message, BannersApiErrorMap) || error?.message,
      error?.response,
    );
    return;
  }

  if (isValidationError(error)) {
    handleValidationErrors(error, setApiErrorMessage);
  }
};

const handleValidationErrors = (error, setApiErrorMessage) => {
  if (!error?.response?.data?.errors) return;
  if (error?.response?.data?.message?.toLowerCase() !== "ValidationError".toLowerCase()) return;
  if (!isValidationError(error)) return;

  const validationErrors = error?.response?.data?.errors;
  if (validationErrors) {
    setApiErrorMessage(error.response.data);
  }
};
