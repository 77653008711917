import React from "react";
import { connect } from "react-redux";
import { DBErrors } from "@db_ui/component-library";
import "./DBError.scss";

export const DBErrorModal = ({ errors }) => {
  if (!errors.length) {
    return null;
  }
  return <DBErrors errors={errors} />;
};

const mapStateToProps = (state) => {
  return {
    errors: state.dbErrorResponse.dbError,
  };
};

export default connect(mapStateToProps)(DBErrorModal);
