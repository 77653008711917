import { deleteBannerApiCall } from "../../../../../utilities/api-calls/BannersApiCalls";
import { handleBannerErrors } from "./bannerErrorHandler";

export const handleDeleteBanner = (bannerID, setShouldBannersUpdate, fromModal, handleModal) => {
  const handleDeleteBannerResponse = (res) => {
    if (res) {
      setShouldBannersUpdate(true);
    }
  };

  const handleDeleteBannerCallback = (res, err) => {
    handleBannerErrors(err);
    handleDeleteBannerResponse(res);
  };

  deleteBannerApiCall(bannerID, handleDeleteBannerCallback);

  if (fromModal) {
    handleModal();
  }
};
