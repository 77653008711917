import React, { Component } from "react";
import Header from "./common/Header";
// import './css/bootstrap-theme.min.css';
import "./css/glyphicons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/bootstrap-polyfill.css";
const Footer = React.lazy(() => import("./common/Footer"));
import "./PageLayout.scss";

class PageLayout extends Component {
  render() {
    return (
      <div className="Site">
        <Header />
        <div className="content-container">{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}
export default PageLayout;
