import decode from "jwt-decode";
import ServerUtils from "./ServerUtils";
import axios from "axios";

export default class AuthService {
  constructor(domain) {
    this.domain = domain || ServerUtils.apiBaseUrlProd();
    this.fetch = this.fetch.bind(this);
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  login(username, password) {
    // Get a token
    let theBody = JSON.stringify({
      username,
      password,
    });
    let url = ServerUtils.apiBaseUrlProd() + "/login";
    console.log("LoginURL is " + url);
    return this.fetch(url, theBody).then((res) => {
      if (res.result != null) this.setToken(res.result);
      else return Promise.resolve(res);
    });
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken();

    return !!token && !this.isTokenExpired(token); // handwaiving here
  }

  static isLoggedIn() {
    let as = new AuthService();
    return as.loggedIn();
  }
  static isAdmin() {
    let as = new AuthService();
    let pay = as.getTokenPayload();
    if (!pay) return false;
    if (!pay.isAdmin) return false;

    return pay.isAdmin;
  }

  getTokenPayload() {
    try {
      const decoded = decode(this.getToken());
      return decoded;
    } catch (err) {
      console.log(err);
      this.logout();
      return null;
    }
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now()) {
        return true;
      } else return false;
    } catch (err) {
      this.logout();
      return false;
    }
  }

  setToken(idToken) {
    // Saves user token to sessionStorage
    sessionStorage.setItem("id_token", idToken);
  }

  getToken() {
    // Retrieves the user token from sessionStorage
    return sessionStorage.getItem("id_token");
  }

  logout(history) {
    const as = new AuthService();
    const token = as.getToken();
    const url = ServerUtils.apiBaseUrlProd() + "/auths2/logout";
    let theData = {
      token: token,
    };

    axios.post(url, theData);
    sessionStorage.removeItem("id_token");
    if (localStorage.getItem("$draw-login-time")) {
      localStorage.removeItem("$draw-login-time");
    }
    ServerUtils.doAuthValidate(history);
    // Clear user token and profile data from localStorage
  }

  getProfile() {
    return decode(this.getToken());
  }

  fetch(url, body) {
    // performs api calls sending the required authentication headers
    const theHeaders = {
      //            'Accept': 'application/json',
      "Content-Type": "application/json",
    };

    if (this.loggedIn()) {
      theHeaders["Authorization"] = "Bearer " + this.getToken();
    }
    // converted to axios from xhr post request
    return axios
      .post(url, body, {
        headers: theHeaders,
      })
      .then(this._checkStatus)
      .then((response) => response.data);
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      let error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
