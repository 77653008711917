export const DefaultApiErrorMap = {
  ErrorMapId: "default-api-error-map", // every error-map must have this property to be able to identify the map

  401: "User not authorized.",
  403: "This action is forbidden.",
  404: "Endpoint not found.",
  405: "Endpoint does not allow this method.",
  500: "Internal Server Error.",
  AdditionalItemNotValid: "The item is not valid with the AdditionalItem schema.",
  AdditionalPropertiesNotValid: "The additional properties are not valid.",
  ArrayExpected: "An array is expected.",
  ArrayItemNotValid: "An array item is not valid.",
  Base64Expected: "A Base64 string is expected.",
  BooleanExpected: "A boolean is expected.",
  DateExpected: "A date is expected.",
  DateTimeExpected: "A date time is expected.",
  EmailExpected: "An email is expected.",
  ExcludedSchemaValidates: "The object matches the not allowed schema.",
  GuidExpected: "A valid GUID is expected.",
  HostnameExpected: "An hostname is expected.",
  IntegerExpected: "An integer is expected.",
  IntegerNotMultipleOf: "The integer is not a multiple of the given integer.",
  IntegerTooBig: "The integer is too big.",
  InternalServerError: "Internal Server Error.",
  IpV4Expected: "An IP v4 address is expected.",
  IpV6Expected: "An IP v6 address is expected.",
  ItemsNotUnique: "The items in the array are not unique.",
  JsonFormatError: "Payload sent to the server is not a valid JSON (JSONFormatError).",
  NoAdditionalPropertiesAllowed: "Additional / unspecified properties are not allowed.",
  NoTypeValidates: "No type of the types does validate.",
  NotAllOf: "The object is not all of the given schemas.",
  NotAnyOf: "The object is not any of the given schemas.",
  NotFoundError: "Not Found.",
  NotInEnumeration: "The value is not one of the allowed enumerations.",
  NotOneOf: "The object is not one of the given schemas.",
  NullExpected: "An array is expected.",
  NumberExpected: "A number is expected.",
  NumberNotMultipleOf: "The number is not a multiple of the given number.",
  NumberTooBig: "The number is too big.",
  NumberTooSmall: "The number is too small.",
  ObjectExpected: "An object is expected.",
  PatternMismatch: "The Regex pattern does not match.",
  PropertyRequired: "The property is required but not found.",
  StringExpected: "A string is expected.",
  StringTooLong: "The string is too long.",
  StringTooShort: "The string is too short.",
  TimeExpected: "A time is expected.",
  TimeSpanExpected: "A time-span is expected.",
  TooFewItems: "The array contains too few items.",
  TooFewProperties: "There are too few properties in the tuple.",
  TooManyItems: "The array contains too many items.",
  TooManyItemsInTuple: "The array tuple contains too many items.",
  TooManyProperties: "There are too many properties in the object.",
  UriExpected: "A URI is expected.",
  UuidExpected: "A valid UUID is expected.",
  ValidationError: "Validation error, please check the form for error messages.",
};
