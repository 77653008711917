import { handleCreateBanner } from "../components/handleCreateBanner";
import { handleUpdateBanner } from "../components/handleUpdateBanner";
import { handleDeleteBanner } from "../components/handleDeleteBanner";
import { handleUpdateBannerStatus } from "../components/handleUpdateBannerStatus";
import { handleGetAllBanners } from "../components/handleGetAllBanners";

const mismatchTimeErrorMessage = "Start date can not be greater than expiration date.";

const activeValue = (value) => {
  if (value === "inactive" || value === false) {
    return false;
  }
  return true;
};

export const handleSave = (
  title,
  message,
  type,
  active,
  expiresIn,
  startsAt,
  history,
  setBannerDateValidationMessage,
  setApiErrorMessage,
) => {
  let start = startsAt.seconds(0);
  let end = expiresIn.seconds(0);

  if (start >= end) {
    setBannerDateValidationMessage(mismatchTimeErrorMessage);
    return;
  }

  const payload = {
    title: title,
    message: message,
    type: type?.value ? type.value : type,
    isActive: active?.value ? activeValue(active.value) : activeValue(active),
    startDate: start,
    endDate: end,
  };

  handleCreateBanner(payload, history, setApiErrorMessage);
};

export const handleUpdate = (
  bannerID,
  title,
  message,
  bannerType,
  active,
  expiresIn,
  startsAt,
  history,
  setBannerDateValidationMessage,
  setApiErrorMessage,
) => {
  let start = startsAt.seconds(0);
  let end = expiresIn.seconds(0);

  if (start >= end) {
    setBannerDateValidationMessage(mismatchTimeErrorMessage);
    return;
  }

  const payload = {
    title: title,
    message: message,
    type: bannerType.value,
    isActive: active?.value ? activeValue(active.value) : activeValue(active),
    startDate: start,
    endDate: end,
  };

  handleUpdateBanner(bannerID, payload, history, setApiErrorMessage);
};

export const handleDelete = (bannerID, setShouldBannersUpdate, fromModal, handleModal) => {
  setShouldBannersUpdate(false);

  handleDeleteBanner(bannerID, setShouldBannersUpdate, fromModal, handleModal);
};

export const handleSetActive = (bannerID, active, setShouldBannersUpdate) => {
  setShouldBannersUpdate(false);

  const payload = {
    isActive: active?.value ? activeValue(active.value) : activeValue(active),
  };

  handleUpdateBannerStatus(bannerID, payload, setShouldBannersUpdate);
};

export const handleGetAll = async (setBanners, bannerID) => {
  handleGetAllBanners(setBanners, bannerID);
};
