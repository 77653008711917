import React, { Component } from "react";
import { Container, Nav, Navbar, NavItem, NavDropdown } from "react-bootstrap";
import AuthService from "../AuthService";
import { DBSession } from "@db_ui/component-library";
import logo from "../images/logo-drawbridge-partners.png";
import { Link, withRouter } from "react-router-dom";
import "./header.scss";
import { sessionLogoutApi, updateSessionApi, validateSessionApi } from "../api/sessionApi";

class Header extends Component {
  render() {
    let as = new AuthService();
    if (as.loggedIn() === false) {
      return <div />;
    }

    let loggedIn = as.loggedIn() === true;

    return (
      <div className="header-container">
        <Navbar>
          <Container style={{ justifyContent: "start" }}>
            <Navbar.Brand>
              <img src={logo} alt="DrawBridge Partners LLC" />
            </Navbar.Brand>
            {/* <Nav pullLeft> */}
            <Nav>
              {/* <NavItem href="/dashboard">Home</NavItem> */}
              <NavItem className="nav--link">
                <Link to={"/vddq"}>DDQs</Link>
              </NavItem>
              {/* <NavItem href="/ddqtemplate">DDQTemplates</NavItem> */}
              <NavDropdown
                title={
                  <>
                    <span>Templates </span>
                    <span className="caret"></span>
                  </>
                }
                id="basic-nav-dropdown"
                className="nav--link"
              >
                <NavDropdown.Item className="dropdown--link" as="span">
                  <Link to="/ddqtemplate">DDQ </Link>
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown--link" as="span">
                  <Link to="/acltemplate">ACL </Link>
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown--link" as="span">
                  <Link to="/alertTemplate">Alert(s)</Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavItem>
                <Link to="/org-client">Organizations</Link>
              </NavItem>
              <NavItem>
                <Link to="/Questions">Questions</Link>
              </NavItem>
              <NavItem>
                <Link to="/tags">Tags</Link>
              </NavItem>
              <NavDropdown
                title={
                  <>
                    <span>ConnectR </span>
                    <span className="caret"></span>
                  </>
                }
                id="basic-nav-dropdown"
                className="nav--link"
              >
                <NavDropdown.Item className="dropdown--link" as="span">
                  <Link to="/inventory">Scan Boxes</Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavItem className="nav--link">
                <Link to="/users">Users</Link>
              </NavItem>
              <NavDropdown
                title={
                  <>
                    <span>Utility </span>
                    <span className="caret"></span>
                  </>
                }
                id="basic-nav-dropdown"
                className="nav--link"
              >
                <NavDropdown.Item className="dropdown--link" as="span">
                  <Link to="/utility/banners">Banners</Link>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Container>
          <Container style={{ justifyContent: "end" }}>
            <Nav>
              <NavItem className="nav--link">
                <Link to="/settings">Settings</Link>
              </NavItem>
              <NavItem
                className="nav--link"
                onClick={() => {
                  as.logout(this.props.history);
                }}
              >
                <span>Logout</span>
              </NavItem>
              <NavItem className="session-clock-nav-item">
                {!!loggedIn ?
                  <DBSession
                    validateSessionApi={validateSessionApi}
                    sessionLogoutApi={sessionLogoutApi}
                    updateSessionApi={updateSessionApi}
                  />
                  : null}</NavItem>
            </Nav>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(Header);
