import { handleDbErrorResponseModal } from "../../redux/actions/db_error_handling";
import { getResponseDetails } from "./get-response-details";
import store from "../../redux/store";

export const showErrorFromResponse = (errorMessage, response) => {
  const details = getResponseDetails(response);
  console.error(`[showErrorFromResponse]: MESSAGE: `, errorMessage);
  console.error(`[showErrorFromResponse]: DETAILS: `, details);

  store.dispatch(handleDbErrorResponseModal({ message: errorMessage, details }));
};
