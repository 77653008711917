import librariesToDisplay from "./librariesToDisplay";

const createVersionObj = (rawVersionInfo) => {
    const { dependencies, ...versionInfo } = rawVersionInfo;
    const dependenciesToDisplay = [];
    for (const libName of librariesToDisplay) {
        if (dependencies[libName]) {
            dependenciesToDisplay.push({
                name: libName,
                version: dependencies[libName].version
            });
        }
    }
    return {
        ...versionInfo,
        dependencies: dependenciesToDisplay
    };
}

export default createVersionObj;