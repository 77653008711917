import React, { useEffect, useState } from "react";
import { Container } from "react-grid-system";
import CoreTable from "../../../../core/ag-grid";
import { bannerListDefs } from "./columnDefs";
import styled from "styled-components";
import { Button } from "reactstrap";
import { handleDelete, handleGetAll } from "../data/data-calls";
import BannerModal from "../components/BannerModal";

const BannerList = ({ history }) => {
  const [allBanners, setBanners] = useState([]);
  const [shouldBannersUpdate, setShouldBannersUpdate] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentBannerID, setBannerID] = useState("");

  const handleModal = (bannerID) => {
    setBannerID(bannerID);
    setShowModal(!showModal);
  };

  const gridOptions = {
    defaultColDef: {
      width: 500,
      resizable: true,
      filterParams: { buttons: ["reset"] },
    },
    suppressHorizontalScroll: false,
    rowSelection: "multiple",
    columnDefs: bannerListDefs,
  };

  useEffect(() => {
    const getBanners = async () => {
      await handleGetAll(setBanners, null);
    };
    if (shouldBannersUpdate) {
      getBanners();
    }
  }, [shouldBannersUpdate]);

  const height = window.innerHeight - 290;

  const onGridReady = (params) => {
    params.api.setShouldBannersUpdate = setShouldBannersUpdate;
    params.api.shouldBannersUpdate = shouldBannersUpdate;
    params.api.setError = setError;
    params.api.history = history;
    params.api.showModal = handleModal;
    params.api.sizeColumnsToFit();
    params.api.redrawRows();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });
  };

  const handleAdd = () => {
    history.push("/utility/banners/create");
  };

  return (
    <Container>
      <BannerModal
        showModal={showModal}
        handleClose={handleModal}
        handleDelete={handleDelete}
        setShouldBannersUpdate={setShouldBannersUpdate}
        bannerID={currentBannerID}
        setError={setError}
      />
      <Notice>Please do not enable more than three banners at one time.</Notice>
      {error?.length ? <ErrorToast>{error}</ErrorToast> : null}
      <ListHeader>
        <>
          <h2>Banner History</h2>
          <ButtonGroup>
            <Button color="primary" onClick={handleAdd}>
              Add New
            </Button>
          </ButtonGroup>
        </>
      </ListHeader>
      <CoreTable gridOptions={gridOptions} rowData={allBanners} onGridReady={onGridReady} height={height} />
    </Container>
  );
};

const ListHeader = styled.div`
  width: 100%;
  background-color: #1c4587;
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 4px;
  color: #ffffff;
`;

const ButtonGroup = styled.div``;

const ErrorToast = styled.div`
  width: 100%;
  padding: 16px;
  background-color: red;
  color: #fff;
`;

const Notice = styled.div`
  font-weight: 700;
  color: salmon;
  text-align: center;
`;
export default BannerList;
