import { createBannerApiCall } from "../../../../../utilities/api-calls/BannersApiCalls";
import { handleBannerErrors } from "./bannerErrorHandler";

export const handleCreateBanner = (payload, history, setApiErrorMessage) => {
  const handleCreateBannerResponse = (res) => {
    if (res) {
      history.push("/utility/banners");
    }
  };

  const handleCreateBannerCallback = (res, err) => {
    handleBannerErrors(err, setApiErrorMessage);
    handleCreateBannerResponse(res);
  };

  createBannerApiCall(payload, handleCreateBannerCallback);
};
