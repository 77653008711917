import moment from "moment";

import { dateComparator2, GeneralComparator } from "../../../../core/ag-grid/comparators";
import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { handleSetActive } from "../data/data-calls";

const dateFilterComparator = (filterLocalDateAtMidnight, cellValue) => {
  const cellDate = moment(cellValue).startOf("day").toDate();

  if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
    return 0;
  }

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }

  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
};

export const bannerListDefs = [
  {
    headerName: "ID",
    field: "ID",
    colId: "ID",
    filter: "agTextColumnFilter",
    sortable: false,
    hide: true,
    flex: 1,
  },
  {
    headerName: "Title",
    field: "title",
    colId: "title",
    flex: 1,
    filter: "agTextColumnFilter",
    comparator: GeneralComparator,
  },
  {
    headerName: "Type",
    field: "type",
    colId: "type",
    flex: 1,
    filter: "agTextColumnFilter",
    comparator: GeneralComparator,
    valueGetter: (params) => {
      if (params.data.type === "maintenance" || params.data.type === "Maintenance") {
        return "Maintenance";
      }
      if (params.data.type === "announcement" || params.data.type === "Announcement") {
        return "Announcement";
      }
    },
  },
  {
    headerName: "Status",
    field: "isActive",
    colId: "isActive",
    flex: 1,
    filter: "agTextColumnFilter",
    comparator: GeneralComparator,
    valueGetter: (params) => {
      if (params.data.isActive) {
        return "Active";
      } else {
        return "Inactive";
      }
    },
  },
  {
    headerName: "Last Active",
    field: "startDate",
    colId: "stateDate",
    flex: 1,
    filter: "agDateColumnFilter",
    filterParams: {
      debounceMs: 500,
      comparator: dateFilterComparator,
    },
    comparator: dateComparator2,
    valueFormatter: (params) => {
      return moment.utc(params.data.startDate).local().format("LLL");
    },
  },
  {
    headerName: "Expires",
    field: "endDate",
    colId: "endDate",
    flex: 1,
    filter: "agDateColumnFilter",
    filterParams: {
      debounceMs: 500,
      comparator: dateFilterComparator,
    },
    comparator: dateComparator2,
    valueFormatter: (params) => {
      return moment.utc(params.data.endDate).local().format("LLL");
    },
  },
  {
    headerName: "Action",
    field: "action",
    colId: "action",
    sortable: false,
    flex: 1,
    suppressMenu: true,

    cellRenderer: (params) => {
      let isActive = params.data.isActive;
      let bannerID = params.data.bannerID;
      let refresh = params.api.setShouldBannersUpdate;
      let error = params.api.setError;
      let history = params.api.history;
      let showModal = params.api.showModal;

      let setOpposite = isActive === true ? false : true;

      const moveToID = () => {
        history.push(`/utility/banners/${bannerID}`);
      };
      return (
        <IconContainer>
          <StyledIcon icon="pen" color="#1C4587" hover="#000000" onClick={moveToID} />
          <StyledIcon
            icon={"power-off"}
            color={isActive ? "#1BC47D" : "#F94E55"}
            hover="#000000"
            onClick={() => handleSetActive(bannerID, setOpposite, refresh)}
          />
          <StyledIcon
            icon="trash"
            color="#F94E55"
            hover="#000000"
            onClick={() => showModal(bannerID, refresh, error)}
          />
        </IconContainer>
      );
    },
  },
];

const IconContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 14px;
  cursor: pointer;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${(props) => (props.color ? props.color : "#1C4587")};
`;
