import moment from "moment";
import { data } from "react-dom-factories";
import { ForcePubSortLogic, StringFieldSortingLogic, dateComparatorLogic } from "./comparator-logic";

export function dateTimeComparator(filterLocalDateAtMidnight, cellValue) {
  return dateComparatorLogic(filterLocalDateAtMidnight, cellValue);
}

export const dateComparator = (filterValue, cellValue) => {
  const cellDateValue = moment(cellValue).startOf("day").toDate();
  const filterDateValue = moment(`${moment(filterValue).format("YYYY-MM-DDTHH:mm:ss.sss")}Z`)
    .startOf("day")
    .toDate();

  const dateA = cellDateValue.getTime();
  const dateB = filterDateValue.getTime();

  if (dateA === dateB) {
    return 0;
  }
  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
};

export function dateComparator2(filterLocalDateAtMidnight, cellValue) {
  if (cellValue == null) {
    return 0;
  }

  var momentDate = moment(cellValue).format("LL");
  var momentDate1 = moment(filterLocalDateAtMidnight).format("LL");

  if (momentDate <= momentDate1) {
    return -1;
  }
  if (momentDate >= momentDate1) {
    return 1;
  }

  return 0;
}

export function GeneralComparator(valueA, valueB, nodeA, nodeB, isInverted) {
  const trimA = StringFieldSortingLogic(valueA, isInverted);
  const trimB = StringFieldSortingLogic(valueB, isInverted);
  return trimA.toLowerCase().localeCompare(trimB.toLowerCase());
}

export function ProgressComparator(valueA, valueB, nodeA, nodeB, isInverted) {
  let fixA = nodeA.data.perCentComplete * 100;
  let fixB = nodeB.data.perCentComplete * 100;
  let statusA = nodeA.data.Status;
  let statusB = nodeB.data.Status;
  let isAforceComplete = nodeA.data.answeredCount !== nodeA.questionCount;
  let isBforceComplete = nodeB.data.answeredCount !== nodeB.questionCount;

  //handles published status which have been force completed.

  if (statusA === "pub" && statusB === "draft") {
    if (isAforceComplete) {
      fixA = 100;
    }
    if (isBforceComplete) {
      fixB = 99;
    }
  }

  if (nodeA.data.Status === "draft" && nodeB.data.Status === "pub") {
    if (isAforceComplete) {
      fixA = 99;
    }
    if (isBforceComplete) {
      fixB = 100;
    }
  }

  if (fixA === fixB) return 0;
  return fixA > fixB ? 1 : -1;
}

export function StatusComparator(valueA, valueB, nodeA, nodeB) {
  const nodeA_QuestionCount = nodeA.data.questionCount;
  const nodeA_AnswerCount = nodeA.data.answeredCount;
  const nodeB_QuestionCount = nodeB.data.questionCount;
  const nodeB_AnswerCount = nodeB.data.answeredCount;

  const firstValue = ForcePubSortLogic(valueA, nodeA_QuestionCount, nodeA_AnswerCount);

  const secondValue = ForcePubSortLogic(valueB, nodeB_QuestionCount, nodeB_AnswerCount);

  if (firstValue === secondValue) return 0;

  return firstValue > secondValue ? 1 : -1;
}
