import { updateBannerApiCall } from "../../../../../utilities/api-calls/BannersApiCalls";
import { handleBannerErrors } from "./bannerErrorHandler";

export const handleUpdateBanner = (bannerID, payload, history, setApiErrorMessage) => {
  const handleUpdateBannerResponse = (res) => {
    if (res) {
      history.push("/utility/banners");
    }
  };

  const handleUpdateBannerCallback = (res, err) => {
    handleBannerErrors(err, setApiErrorMessage);
    handleUpdateBannerResponse(res);
  };
  updateBannerApiCall(bannerID, payload, handleUpdateBannerCallback);
};
