import * as types from "../constants";

export const handleErrorResponseModal = (response) => {
  return (dispatch) => {
    return dispatch({
      type: types.ERROR_POPUP_REQUEST_DISPATCHED,
      payload: response,
    });
  };
};

export const handleErrorResponseModalReset = () => {
  return (dispatch) => {
    return dispatch({
      type: types.ERROR_POPUP_REQUEST_RESET,
    });
  };
};
