export const EXAMPLE = "EXAMPLE";

//AG-GRID STATE
export const SAVE_GRID_COLUMN_STATE = "SAVE_GRID_COLUMN_STATE";
export const SAVE_GRID_COLUMN_GROUP_STATE = "SAVE_GRID_COLUMN_GROUP_STATE";
export const SAVE_GRID_SORT_MODEL = "SAVE_GRID_SORT_MODEL";
export const SAVE_GRID_FILTER_MODEL = "SAVE_GRID_FILTER_MODEL";

//ERROR HANDLING
export const ERROR_POPUP_REQUEST = "ERROR_POPUP_REQUEST";
export const ERROR_POPUP_REQUEST_DISPATCHED = "ERROR_POPUP_REQUEST_DISPATCHED";
export const ERROR_POPUP_REQUEST_RESET = "ERROR_POPUP_REQUEST_RESET";

//SPINNER STATE
export const LOADING_SPINNER_HIDE = "LOADING_SPINNER_HIDE";
export const LOADING_SPINNER_SHOW = "LOADING_SPINNER_SHOW";

//DB ERROR HANDLING
export const DB_ERROR_POPUP_REQUEST_DISPATCHED = "DB_ERROR_POPUP_REQUEST_DISPATCHED";
export const DB_ERROR_POPUP_REQUEST_RESET = "DB_ERROR_POPUP_REQUEST_RESET";
export const DB_OOPS_POPUP_REQUEST_DISPATCHED = "DB_OOPS_POPUP_REQUEST_DISPATCHED";
export const DB_OOPS_POPUP_REQUEST_RESET = "DB_OOPS_POPUP_REQUEST_RESET";
