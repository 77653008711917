import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";

import PageNotFound from "./components/common/PageNotFound";
import PageLayout from "./components/PageLayout";

import { BrowserRouter, Switch, Route } from "react-router-dom";

//app styles
import "./App.css";
import "./index.css";

//services
import AdminPrivateRoute from "./components/common/PrivateRoute";
import AuthService from "./components/AuthService";
import EditAclTemplate from "./components/edit-org-page/templates/edit-acl-template";
import ACLTemplates from "./components/edit-org-page/templates/acl-template";
import FullScreenSpinner from "./components/FullScreenSpinner";

//font awesome
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import BannerList from "./components/utility/app-utility/banners/views/BannerList";
import { DisplayBackendResponseError } from "./components/common/notification/CatchErrorModal";
import { handleErrorResponseModalReset } from "./redux/actions/error_handling";
import Spinner from "./components/common/spinner";
import DBOopsModal from "./components/common/error/DBOopsModal";
import DBErrorModal from "./components/common/error/DBErrorModal";
import { LicenseManager } from "@ag-grid-enterprise/core";
import useGetAndSetAppVersions from "./utilities/versions/useGetAndSetAppVersions";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE);

library.add(fas, fab, fal, fad, far);
dom.watch();

window.env = {
  REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT,
  IS_ADMIN_APP: process.env.IS_ADMIN_APP
};

//view lazy loads
const OrgLink = React.lazy(() => import("./components/org-link-component"));
const VendorDDQ = React.lazy(() => import("./components/VendorDDQ"));
const newLoginPage = React.lazy(() => import("./components/new-login-Page/newLogin"));
const Logout = React.lazy(() => import("./components/common/Logout"));
const DDQTemplateList = React.lazy(() => import("./components/DDQTemplateList/DDQTemplateList"));
const UserList = React.lazy(() => import("./components/users/UserList"));
const Footer = React.lazy(() => import("./components/common/Footer"));
const ClientQuestionComp = React.lazy(() => import("./components/Questions/Questions"));
const OrgClient = React.lazy(() => import("./components/org-client"));
const UserProfile = React.lazy(() => import("./components/user-settings/user-profile"));
const AnalyticsPage = React.lazy(() => import("./components/analytics-page/analytics-main"));
const EditOrgPage = React.lazy(() => import("./components/edit-org-page/edit-org-page"));
const TagPage = React.lazy(() => import("./components/TagManagement"));
const RiskEditor = React.lazy(() => import("./components/risk-editor/RiskEditor"));
const TemplateQuestionList = React.lazy(() => import("./components/template-question-page/DDQTemplateCreator"));
const TemplateACL = React.lazy(() => import("./components/edit-org-page/templates/edit-acl-template"));
const InventoryMainPage = React.lazy(() => import("./components/inventory-main/inventory-main"));
const AlertTemplateMain = React.lazy(() => import("./components/alert-template/alert-template-main"));
const BannerSetup = React.lazy(() => import("./components/utility/app-utility/banners/views/BannerSetup"));

const App = ({ errorResponse, handleErrorResponseModalReset, showSpinner }) => {
  const [errorsArr, setErrorsArr] = useState([]);
  const [shouldHaveError, setShouldHaveError] = useState(false);
  const [shouldRerender, setShouldRerender] = useState(false);

  const handleSetToErrorsArray = (response) => {
    setErrorsArr(response);
  };

  useGetAndSetAppVersions();

  useEffect(() => {
    const isRootPage = window.location.pathname === "/";
    const isNotLoggedIn = AuthService.isLoggedIn() === false;

    if (isRootPage && isNotLoggedIn) {
      window.location.pathname = "/login";
    }
  }, []);

  useEffect(() => {
    if (errorResponse === null) return;

    if (!errorResponse?.active) return;

    if (errorsArr.includes(errorResponse)) return;

    errorsArr.push(errorResponse);

    handleSetToErrorsArray(errorsArr);
    if (shouldRerender || !shouldRerender) {
      setShouldRerender(!shouldRerender);
    }
  }, [errorResponse, errorsArr, shouldRerender]);

  useEffect(() => {
    if (shouldHaveError || !shouldHaveError) {
      errorsArr.splice(0, 1);

      if (errorsArr?.length > 0) {
        handleSetToErrorsArray(errorsArr);
        return;
      }
      setErrorsArr([]);

      handleErrorResponseModalReset(errorResponse);
    }
  }, [shouldHaveError]);

  useEffect(() => {
    fetch("/meta.appVersion.json")
      .then((response) => response.json())
      .then((meta) => {
        global.appVersion = meta?.globalAppVersion;
      })
      .catch((e) => {
        console.warn("Unable to find meta.appVersion.json file: ", e);
      });
  }, []);

  const handleShouldSetError = () => {
    setShouldHaveError(!shouldHaveError);
  };

  const handleAllErrors = (errors) => {
    return errors.map((err) => {
      return (
        <DisplayBackendResponseError
          key={err?.url + err?.time}
          info={err}
          setShouldHaveError={handleShouldSetError}
          shouldHaveError={shouldHaveError}
        />
      );
    });
  };

  return (
    <BrowserRouter getUserConfirmation={null} basename={process.env.REACT_APP_PUBLIC_URL}>
      <Suspense fallback={<FullScreenSpinner />}>
        <PageLayout>
          {errorsArr ? handleAllErrors(errorsArr) : null}
          <DBErrorModal />
          <DBOopsModal />
          <Spinner data={{ state: showSpinner > 0 }} />
          <Switch>
            <Route path="/login" component={newLoginPage} />
            <Route path="/logout" component={Logout} />
            <Route path="/resetPassword/:id" component={newLoginPage} />
            <AdminPrivateRoute path="/org-client" component={OrgClient} />

            <AdminPrivateRoute path="/settings" component={UserProfile} />
            <AdminPrivateRoute path="/orglink" component={OrgLink} />
            <AdminPrivateRoute path="/vddq" component={VendorDDQ} />
            <AdminPrivateRoute path="/editorg/:id" component={EditOrgPage} />

            <AdminPrivateRoute path="/inventory" component={InventoryMainPage} />
            <AdminPrivateRoute path="/questions" component={ClientQuestionComp} />
            <AdminPrivateRoute path="/alertTemplate" component={AlertTemplateMain} />
            <AdminPrivateRoute path="/ddqtemplate" component={DDQTemplateList} />
            <AdminPrivateRoute path="/acltemplate/edit/:id" component={EditAclTemplate} />
            <AdminPrivateRoute path="/acltemplate" component={ACLTemplates} />
            <AdminPrivateRoute path="/analytics" component={AnalyticsPage} />
            <AdminPrivateRoute path="/templateDetail/:id" component={TemplateQuestionList} />
            <AdminPrivateRoute path="/users" component={UserList} />
            <AdminPrivateRoute path="/tags" component={TagPage} />
            <AdminPrivateRoute path="/utility/banners/create" component={BannerSetup} />
            <AdminPrivateRoute path="/utility/banners/:id" component={BannerSetup} />
            <AdminPrivateRoute path="/templateRisk/:id" component={RiskEditor} />
            <AdminPrivateRoute path="/utility/banners" component={BannerList} />
            <Route path="/*" component={VendorDDQ} />
          </Switch>
        </PageLayout>
      </Suspense>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    errorResponse: state.errorResponse,
    showSpinner: state.spinner.showSpinner,
  };
};

const mapDispatchToProps = {
  handleErrorResponseModalReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
