import React from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

const modulesToUse = [...AllModules, ClientSideRowModelModule];

const CoreTable = React.forwardRef((props, ref) => {
  const { height, ...agGridProps } = props;

  let customHeight;
  if (!height) {
    customHeight = 500;
  } else {
    customHeight = height;
  }

  const defaultColDef = {
    minWidth: 100,
    flex: 1,
    sortable: true,
    resizable: true,
    filter: "agTextColumnFilter",
    menuTabs: ["filterMenuTab"],
  };

  return (
    <>
      <div className="ag-theme-alpine" style={{ height: customHeight, width: "100%" }}>
        <AgGridReact
          ref={ref}
          defaultColDef={defaultColDef}
          modules={modulesToUse}
          suppressHorizontalScroll
          {...agGridProps}
        />
      </div>
    </>
  );
});

export default CoreTable;
