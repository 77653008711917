import { getBannersApiCall } from "../../../../../utilities/api-calls/BannersApiCalls";
import { handleBannerErrors } from "./bannerErrorHandler";

export const handleGetAllBanners = (setBanners, bannerID) => {
  const handleGetAllBannersResponse = (res) => {
    if (res) {
      if (bannerID) {
        const filteredData = res.data.filter((rd) => {
          if (rd.bannerID === parseInt(bannerID)) {
            return rd;
          }
          return null;
        });
        setBanners(filteredData);
        return true;
      }
      setBanners(res.data);
    }
  };

  const handleGetAllBannersCallback = (res, err) => {
    handleBannerErrors(err);
    handleGetAllBannersResponse(res);
  };

  getBannersApiCall(handleGetAllBannersCallback);
};
