import { dbAxios } from "../db-axios/axios";
import { DefaultApiErrorMap } from "./api-error-maps/DefaultApiErrorMap";
import { BannersApiErrorMap } from "./api-error-maps/BannersApiErrorMap";

export const BannersErrorMapping = { ...DefaultApiErrorMap, ...BannersApiErrorMap };

export const getBannersApiCall = (callback) => {
  dbAxios({
    endpoint: "/banners/",
    method: "get",
    callback,
    errorMap: BannersErrorMapping,
  });
};

export const createBannerApiCall = (payload, callback) => {
  dbAxios({
    endpoint: "/banners/",
    method: "post",
    data: payload,
    callback,
    errorMap: BannersErrorMapping,
  });
};

export const updateBannerApiCall = (bannerID, payload, callback) => {
  dbAxios({
    endpoint: `/banners/${bannerID}`,
    method: "put",
    data: payload,
    callback,
    errorMap: BannersErrorMapping,
  });
};

export const updateBannerStatusApiCall = (bannerID, payload, callback) => {
  dbAxios({
    endpoint: `/banners/${bannerID}/status`,
    method: "put",
    data: payload,
    callback,
    errorMap: BannersErrorMapping,
  });
};

export const deleteBannerApiCall = (bannerID, callback) => {
  dbAxios({
    endpoint: `/banners/${bannerID}`,
    method: "delete",
    callback,
    errorMap: BannersErrorMapping,
  });
};
