import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";

const BannerModel = ({ showModal, handleClose, handleDelete, setShouldBannersUpdate, bannerID }) => {
  return (
    <Modal className="admin-modal-z-index" show={showModal} onHide={handleClose} transition={false}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Are you sure you want to delete this banner?</div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="danger" onClick={() => handleDelete(bannerID, setShouldBannersUpdate, true, handleClose)}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BannerModel;
