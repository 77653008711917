import { axiosCall, axiosCallAsPromise } from "@db_ui/axios";
import store from "../../redux/store";
import { handleShowSpinner, handleHideSpinner } from "../../redux/actions/loading-spinner";
import { handleDbErrorResponseModal, handleDbOopsResponseModal } from "../../redux/actions/db_error_handling";
import ServerUtils from "../api-calls/ServerUtils";
import AuthService from "../api-calls/AuthService";

const AS = new AuthService();

const handleDBErrorResponse = (message, details) => {
  store.dispatch(handleDbErrorResponseModal({ message, details }));
};

const handleDBOopsResponse = () => {
  store.dispatch(handleDbOopsResponseModal());
};

const getAxiosParams = (params) => {
  const token = AS.getToken();
  const headers = params.headers || {};
  headers["Authorization"] = `Bearer ${token}`;
  return {
    ...params,
    endpoint: ServerUtils.apiBaseUrlProd() + params.endpoint,
    handlers: {
      handleSpinner: (isLoading) => store.dispatch(isLoading ? handleShowSpinner() : handleHideSpinner()),
      handleDBError: handleDBErrorResponse,
      handleDBOops: handleDBOopsResponse,
    },
    validateStatus: (status) => {
      return !(status === 207 || status >= 400); // Success if return true
    },
    headers,
  };
};

export const dbAxios = (params) => {
  axiosCall(getAxiosParams(params));
};

export const dbAxiosAsPromise = (params) => {
  return axiosCallAsPromise(getAxiosParams(params));
};
