export const mainNav = {
  landingPage: "/dashboard",
  routes: [
    {
      title: "Dashboard",
      icon: "fas fa-home",
      url: "/dashboard",
      acl: "H",
      featureName: "dashboard",
    },
    {
      title: "Profile",
      icon: null,
      url: "/profile-setting",
      acl: "H",
      featureName: "profile",
      tabs: [
        {
          id: "account-settings",
          label: "Account Settings",
          acl: "A",
        },
        {
          id: "business-profile",
          label: "Business Profile",
          acl: "H",
        },
        {
          id: "technology-profile",
          label: "Technology Profile",
          acl: "H",
        },
      ],
    },
    {
      title: "Cyber Program",
      icon: "fas fa-globe",
      url: "/cyber-program",
      acl: "H",
      featureName: "cyberProgram",
      tabs: [
        {
          id: "cyber-dashboard",
          label: "Home",
          acl: "H",
        },
        {
          id: "risk-assessment",
          label: "Risk Assessment",
          acl: "H",
        },
        {
          id: "sec-report",
          label: "SEC Readiness Report",
          acl: "H",
        },
        {
          id: "cyber-training",
          label: "Cybersecurity Training",
          acl: "H",
        },
        {
          id: "phising-examination",
          label: "Phishing Examination",
          acl: "H",
        },
      ],
    },

    {
      title: "ConnectR",
      icon: "fas fa-server",
      url: "/connectR",
      acl: "H",
      featureName: "connectR",
      tabs: [
        {
          id: "connect-home",
          label: "Dashboard",
          acl: "H",
        },
        {
          id: "vulnerabilities",
          label: "Vulnerabilities",
          acl: "H",
        },
        {
          id: "connect-assets",
          label: "Asset Inventory",
          acl: "H",
        },
        {
          id: "connect-reports",
          label: "Reports",
          acl: "H",
          isRollout: true,
        },
        {
          id: "connect-scanner",
          label: "Scan Configuraton",
          acl: "H",
          isRollout: true,
        },
        {
          id: "connect-changeLog",
          label: "Change Logs",
          acl: "H",
        },
        {
          id: "connect-alerts",
          label: "Alert Center",
          acl: "H",
        },
        {
          id: "pcReporter",
          label: "PC Reporter Service",
          acl: "H",
        },
        {
          id: "pcr-risk",
          label: "Risk",
          acl: "H",
        },
      ],
    },
    {
      title: "Due Diligence",
      icon: "fas fa-clipboard-list",
      url: "/due-diligence",
      acl: "H",
      featureName: "dueDiligence",
      tabs: [
        {
          id: "due-dashboard",
          label: "Home",
          acl: "H",
        },
        {
          id: "launch-campaign-main",
          label: "Launch Campaign",
          acl: "H",
        },
        {
          id: "third-party",
          label: "Third Party Vendor",
          acl: "H",
        },
        {
          id: "portfolio-investment",
          label: "Portfolio Company / Investment",
          acl: "H",
        },
        {
          id: "active-ddq",
          label: "Outgoing DDQ(s)",
          acl: "H",
        },
        {
          id: "client_due_diligence_ddq-incoming",
          label: "Incoming DDQ(s)",
          acl: "H",
        },
      ],
    },

    {
      title: "Business Continuity",
      icon: "fas fa-building",
      url: "/business-continuity",
      acl: "H",
      featureName: "businessContinuity",
      tabs: [
        {
          id: "business-dashboard",
          label: "Home",
          acl: "H",
        },
        {
          id: "bus-cont-plan",
          label: "Business Continuity Plan",
          acl: "H",
        },
        {
          id: "bus-impact",
          label: "Business Impact Analysis",
          acl: "H",
        },
        {
          id: "phy-security",
          label: "Physical Security Assessment",
          acl: "H",
        },
      ],
    },
    {
      title: "Crisis Communication",
      icon: "fas fa-exclamation-circle",
      url: "/crisis-communication",
      acl: "H",
      featureName: "crisisCommunication",
      tabs: [
        {
          id: "crisis-dashboard",
          label: "Home",
          acl: "H",
        },
        {
          id: "con-bridge",
          label: "Conference Bridge",
          acl: "H",
        },
        {
          id: "employee-tree",
          label: "Employee Call Tree",
          acl: "H",
        },
        {
          id: "call-forwarding",
          label: "Call Forwarding Instructions",
          acl: "H",
        },
        {
          id: "key-third-party",
          label: "Key Third Party Contact Information",
          acl: "H",
        },
        {
          id: "remote-access",
          label: "Remote Access",
          acl: "H",
        },
      ],
    },
    {
      title: "Privacy",
      icon: "fas fa-user-secret",
      url: "/privacy",
      acl: "H",
      featureName: "privacy",
      tabs: [
        {
          id: "privacy-dashboard",
          label: "Home",
          acl: "H",
        },
        {
          id: "pii-invt",
          label: "PII Inventory",
          acl: "H",
        },
        {
          id: "privacy-impact",
          label: "Privacy Impact Assessment",
          acl: "H",
        },
      ],
    },
    {
      title: "Calendar",
      icon: "fas fa-calendar-alt",
      url: "/calendar",
      acl: "H",
      featureName: "calendar",
    },
    {
      title: "Organization Tree",
      icon: "code-branch",
      url: "/org-tree",
      featureName: "orgTree",
      isCustomButton: false,
      acl: "H",
    },
    {
      title: "Dark Web",
      icon: "fas fa-user-secret",
      url: "/darkweb",
      acl: "H",
      featureName: "DarkWeb",
    },
    {
      title: "Track",
      icon: "fas fa-calendar-plus",
      url: "/track",
      acl: "H",
      featureName: "Track",
    },
    {
      title: "Benchmarks",
      icon: "fas fa-calendar-plus",
      url: "/benchmarks",
      acl: "H",
      featureName: "Benchmarking",
      isCustomButton: true,
      tabs: [
        {
          id: "benchmarks-dashboard",
          label: "Dashboard",
          acl: "H",
        },
        {
          id: "benchmarks-risk-details",
          label: "Risk Details",
          acl: "H",
        },
      ],
    },
    {
      title: "Regulatory Preparedness",
      icon: "fas fa-calendar-plus",
      url: "/regulatory-preparedness",
      acl: "H",
      featureName: "regulatory-preparedness",
      isCustomButton: true,
      tabs: [
        {
          id: "sec",
          label: "SEC",
          acl: "H",
        },
        {
          id: "dora",
          label: "DORA",
          acl: "H",
        },
      ],
    },
    {
      title: "Forecasting",
      icon: "fas fa-calendar-plus",
      url: "/forecasting",
      acl: "H",
      featureName: "Forecasting",
      isCustomButton: true,
      tabs: [
        {
          id: "planning",
          label: "Planning",
          acl: "H",
        },
        {
          id: "remediation-report",
          label: "Remediation Report",
          acl: "H",
        },
      ],
    },
    {
      title: "Portfolio Monitor Platform",
      icon: "fas fa-calendar-plus",
      url: "/portfolio-monitor-platform",
      acl: "H",
      featureName: "portfolio-monitor-platform",
    },
    {
      title: "Vendor Management",
      icon: "fas fa-calendar-plus",
      url: "/vendorManagement",
      acl: "H",
      featureName: "vendorManagement",
      superAdminRestricted: true,
    },
    {
      title: "Risk Profiler",
      icon: "fas fa-calendar-plus",
      url: "/risk-profiler",
      acl: "H",
      featureName: "risk-profiler",
    },
  ],
};
