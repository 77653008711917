import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { CopyToClipboard } from "../../shared/CopyToClipboard";
import "./errorStyle.css";

export const DisplayBackendResponseError = ({ info, setShouldHaveError, shouldHaveError }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (active) {
      setOpen(true);
    }
  }, [info]);

  const handleClose = () => {
    setOpen(false);
    setShouldHaveError(!shouldHaveError);
  };

  if (!info) return;
  const { url, time, response, request, origin, active } = info;
  let cleanedResponse = response?.replace(/[\/\\']/g, "");
  let cleanedRequest = request?.replace(/[\/\\']/g, "");
  let view = window?.location;

  let stringifyInfo = `
	On View: ${view}
	Error From: ${url}
	Error occurred at: ${time}
	Payload Sent: ${cleanedRequest}
	Response: ${cleanedResponse}
	`;

  return (
    <Modal className="admin-modal-z-index" show={open} onHide={handleClose} size="lg">
      <Modal.Header className="backend-error-header">
        <Modal.Title>ERROR: {origin}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre>
          {`
On View: ${view}

Error From: ${url}
          
Error occurred at: ${time}

Payload Sent: ${cleanedRequest}

Response: ${cleanedResponse}
                    `}
        </pre>
      </Modal.Body>
      <Modal.Footer className="backend-error-footer">
        <CopyToClipboard style={{ marginRight: "8px" }} itemToCopy={stringifyInfo} margin={`0 8px 0 0`} />
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
