import React, { Component } from "react";
import { Row, Col, Container } from "react-grid-system";
import AuthService from "../../../components/AuthService";
import { Button, Input, InputGroup, Label } from "reactstrap";
import "react-table/react-table.css";
import { Link } from "react-router-dom";
import "../access-controls/acl.css";
import { mainNav } from "../../common/acl/acl-admin-template";
import axios from "axios";
import ServerUtils from "../../api/server";
import "./acl.css";
import CoreTable from "../../core/ag-grid";
import { GeneralComparator } from "../../core/ag-grid/comparators";

export default class ACLTemplates extends Component {
  state = {
    collapse: true,
    rowData: [],
    tempName: "",
    tempNotes: "",
    warnNoName: false,
    defaultTemplate: mainNav,
    columnDefs: [
      {
        headerName: "ID",
        field: "templateID",
        filter: "agTextColumnFilter",
        width: 75,
      },
      {
        headerName: "Name",
        field: "templateName",
        filter: "agTextColumnFilter",
        comparator: GeneralComparator,
        width: 300,
      },
      {
        headerName: "Notes",
        field: "notes",
        filter: "agTextColumnFilter",
        comparator: GeneralComparator,
        width: 600,
      },

      {
        headerName: "Action",
        field: "edit",
        cellRenderer: "ActionRender",
      },
    ],
    components: {
      ActionRender: ({ data, rowIndex }) => {
        return <Link to={`/acltemplate/edit/${data.templateID}`}>Edit</Link>;
      },
    },
  };

  renderEditLink = (props) => {
    return <Link to={`/acltemplate/edit/${props.data.templateID}`}>Edit</Link>;
  };
  setTempName = (e) => {
    this.setState({ tempName: e.target.value });
  };

  setNotes = (e) => {
    this.setState({ tempNotes: e.target.value });
  };

  resetWarning = () => {
    this.setState({ warnNoName: false });
  };
  warningNoName = () => {
    return (
      <div onClick={(e) => this.resetWarning()} className="warning">
        <h5>Template name is required!</h5>
      </div>
    );
  };
  addNewTemplate = async () => {
    if (!this.state.tempName) {
      this.setState({ warnNoName: true });
      return;
    }
    let as = new AuthService();
    let token = as.getToken();
    let default_acl = mainNav.routes;
    let landingPage = mainNav.landingPage;
    let url = ServerUtils.apiBaseUrlProd() + "/auths2/createTemplate";
    let payload = {
      token,
      acl: {
        landingPage: landingPage,
        templateName: this.state.tempName,
        notes: this.state.tempNotes,
        routes: default_acl,
      },
    };

    let newTemplate = await axios.post(url, payload);

    let newID = newTemplate?.data?.id;
    if (newTemplate?.data?.code === -1) {
      return;
    }
    if (newTemplate?.data?.code === 0) {
      return this.props.history.push(`/acltemplate/edit/${newID}`);
    }
  };

  renderInputs = () => {
    return (
      <div className="aclTemplate--mainContainer">
        {this.state.warnNoName ? <div>{this.warningNoName()}</div> : null}
        <h3>ACL Templates</h3>
        <div className="aclTemplate--inputContainer">
          <div>
            <Label>Template Name*</Label>
            <InputGroup className="aclTemplate--inputGroup">
              <Input
                style={{ width: "500px", flex: "none" }}
                onChange={(e) => this.setTempName(e)}
                name="tmpName"
              ></Input>
            </InputGroup>
          </div>
          <div>
            <Label>Notes</Label>
            <InputGroup className="aclTemplate--inputGroup">
              <Input style={{ width: "500px", flex: "none" }} onChange={(e) => this.setNotes(e)} name="notes" />
            </InputGroup>
          </div>
        </div>
        <Button className="aclTemplate--inputGroup" color="primary" onClick={() => this.addNewTemplate()}>
          Create
        </Button>
      </div>
    );
  };

  getRowData = async () => {
    let as = new AuthService();
    let token = as.getToken();

    let url = ServerUtils.apiBaseUrlProd() + "/auths2/getAllTemplates";
    let rowData = await axios.post(url, { token: token }).catch(() => {
      as.logout(this.props.history);
    });

    if (rowData.data.code === -1) {
      return;
    }
    if (rowData.data.code === 0) {
      const mapData = rowData.data.result.map((item) => {
        return {
          templateID: item.templateID,
          templateName: item.templateName,
          notes: item.notes,
        };
      });

      this.setState({ rowData: mapData });
    }
  };

  componentDidMount() {
    this.getRowData();
  }

  render() {
    let gridOptions = {
      defaultColDef: {
        width: 500,
        resizable: true,
      },
      columnDefs: this.state.columnDefs,
      rowData: this.state.rowData,
      components: this.state.components,
      onGridReady: (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        window.addEventListener("resize", () => {
          setTimeout(() => {
            params.api.sizeColumnsToFit();
          });
        });
      },
    };

    return (
      <div className="acl--wrapper">
        <Container>
          <Row>
            <Col>
              {this.renderInputs()}
              <CoreTable gridOptions={gridOptions} rowData={this.state.rowData} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
