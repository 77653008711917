import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

export const CopyToClipboard = ({ itemToCopy }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyToClipboard = () => {
    setCopySuccess(true);
    return window.navigator.clipboard.writeText(itemToCopy);
  };

  useEffect(() => {
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  }, [copySuccess]);

  return (
    <Button disabled={copySuccess} onClick={handleCopyToClipboard}>
      {copySuccess ? "** Copied **" : "Copy To Clipboard"}
    </Button>
  );
};
