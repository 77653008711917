import { useEffect } from "react";
import versions from "../../lib/versions";
import createVersionObj from "./createVersionObj";

const useGetAndSetAppVersions = () => {
    useEffect(() => {
        const versionObj = createVersionObj(versions);
        global.appVersion = versionObj;
        console.log("AppVersion: ", versionObj);
    }, []);
}

export default useGetAndSetAppVersions;