import React from "react";
import { connect } from "react-redux";
import { DBOops } from "@db_ui/component-library";
import { handleDbOopsResponseModalReset } from "../../../redux/actions/db_error_handling";
import "./DBError.scss";

const DBOopsModal = ({ dbOops, handleDbOopsResponseModalReset }) => {
  if (!dbOops) {
    return null;
  }
  return <DBOops key={dbOops} onClose={handleDbOopsResponseModalReset} />;
};

const mapStateToProps = (state) => {
  return {
    dbOops: state.dbErrorResponse.dbOops,
  };
};

const mapDispatchToProps = {
  handleDbOopsResponseModalReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(DBOopsModal);
