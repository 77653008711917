import AuthService from "../AuthService";
import ServerUtils from "../api/server";
import axios from "axios";

export const fetchAccountInfo = (state, setState, handleNetworkCatchError, handleErrorResponseModal, page) => {
  const as = new AuthService();
  const URL = ServerUtils.apiBaseUrlProd() + "/auths2/loginInfo";
  const data = { token: as.getToken() };
  axios
    .post(URL, data)
    .then((response) => {
      if (response.data.code !== 0) {
        handleNetworkCatchError(`${page}/loginInfo`, URL, response.data, data, handleErrorResponseModal);
        return;
      }
      let tmp = state;
      tmp.isSuperAdmin = response?.data?.result?.superAdmin || false;
      setState(tmp);
    })
    .catch((err) => {
      let response = err?.response?.data;
      let data = err?.config?.data;
      handleNetworkCatchError(`${page}/loginInfo`, URL, response, data, handleErrorResponseModal);
    });
};
