//the following config file contains the maxLength numbers for specific input fields.
//validation.input is used on any normal text field and email fields
//validation.searcn is used for any responsive search fields
//validation.phoneNumbers is used for phone number
//validation.ipAddress is used for ipAddress input fields
//validation.macAddress is used for macAddress input fields
//validation.textArea is used for textarea inputs (this includes react-quill components)

const validation = {
  input: 254,
  search: 499,
  phoneNumber: 20,
  macAddress: 20,
  ipAddress: 25,
  textArea: 2000,
  boxLocation: 40,
  mfa: 6,
  passwordMin: 12,
  passwordMax: 45,
};

export default validation;
