export const BannersApiErrorMap = {
  ErrorMapId: "banners-api-error-map",
  NotFoundError: "Banner not found,  please try again.",
  StringTooShortTitle: "Banner Title cannot be empty.",
  StringTooShortMessage: "Banner Message cannot be empty.",
  StringTooShortType: "Banner Type cannot be empty.",
  BooleanExpected: "Banner Status cannot be empty.",
  DateTimeExpectedStartDate: "Start date can not be empty.",
  DateTimeExpectedDueDate: "Due date can not be empty.",
  DueDateEarlierThanStartDate: "Start date can not be greater than expiration date.",
};
