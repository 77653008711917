import moment from "moment";

export const StringFieldSortingLogic = (val, isInverted) => {
  const isArray = Array.isArray(val);
  let noValue = isInverted ? "AAAAAAAAAAA" : "ZZZZZZZZZZ";
  if (val && isArray) {
    if (val.length === 0 || val === "") {
      return noValue;
    }
    return val[0];
  }
  if ((!val && !isArray) || val === "N/A" || val === "") {
    return noValue;
  }
  if (val === null) {
    return noValue;
  }

  if (typeof val === "number") {
    return val.toString().trim();
  }
  if (typeof val === "string") {
    return val.trim();
  }

  return val;
};

export const ForcePubSortLogic = (value, questionCount, answerCount) => {
  if (value === "pub" && questionCount !== answerCount) {
    return "force_pub";
  }

  if (value === "pub" && questionCount === answerCount) {
    return "pub";
  }

  return value;
};

export const dateComparatorLogic = (filterLocalDateAtMidnight, cellValue) => {
  if (!cellValue || cellValue === "N/A") {
    return;
  }
  const cellValueDate = moment(cellValue).startOf("day").toDate();
  const dateA = new Date(cellValueDate).getTime();
  const dateB = new Date(filterLocalDateAtMidnight).getTime();

  if (dateA == dateB) {
    return 0;
  }
  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
};
