import * as types from "../constants";

export const handleShowSpinner = () => {
  return (dispatch) => {
    return dispatch({
      type: types.LOADING_SPINNER_SHOW,
    });
  };
};

export const handleHideSpinner = () => {
  return (dispatch) => {
    return dispatch({
      type: types.LOADING_SPINNER_HIDE,
    });
  };
};
