import React from "react";
import AuthService from "../AuthService";

class PageNotFound extends React.Component {
  render() {
    let as = new AuthService();
    if (as.loggedIn() === false) {
      as.logout(this.props.history.push("/login"));
    }
    return <h2 className="text-center">Page not found!</h2>;
  }
}

export default PageNotFound;
