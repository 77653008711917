import AuthService from "../AuthService";

export const apiEndPoint = () => {
  return `${process.env.REACT_APP_ENDPOINT}`;
};

class ServerUtils {
  static apiBaseURL() {
    return `${apiEndPoint()}`;
  }
  static cpeMatching() {
    return apiEndPoint() + "/cpeMatch";
  }

  static apiBaseUrlProd() {
    return apiEndPoint();
  }

  static doAuthValidate(history) {
    const as = new AuthService();
    const checkLogIn = as.loggedIn();
    if (checkLogIn === false) {
      window.location.pathname = "/login";
    }
  }
}
export default ServerUtils;
