import React, { Component } from "react";
import "./full-screen-spinner.css";

class FullScreenSpinner extends Component {
  render() {
    return (
      <div className="spinner-page">
        <div className="spinner">
          <div className="loading-text "> DRAWBRIDGE </div>
          <br />
          <div> Loading...</div>
        </div>
      </div>
    );
  }
}

export default FullScreenSpinner;
