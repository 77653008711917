import React from "react";
// First way to import
import { ClipLoader } from "react-spinners";

// Another way to import
import "./main.css";

class Spinner extends React.Component {
  render() {
    const loading = this.props.data.state;

    return (
      <div className="spinner-local">
        <ClipLoader sizeUnit={"px"} size={25} color={"000000"} loading={loading} />
      </div>
    );
  }
}

export default Spinner;
