import * as types from "../constants";

const initialState = {
  origin: null,
  url: null,
  time: null,
  request: null,
  response: null,
  active: null,
};

export const errorResponseReducer = (state = initialState, action = {}) => {
  let response;

  switch (action.type) {
    case types.ERROR_POPUP_REQUEST_DISPATCHED:
      response = {};
      response.origin = action?.payload?.origin;
      response.url = action?.payload?.url;
      response.time = action?.payload?.time;
      response.request = JSON.stringify(action?.payload?.request);
      response.response = JSON.stringify(action?.payload?.response);
      response.active = action?.payload?.active === true ? true : false;

      return {
        ...response,
      };
    case types.ERROR_POPUP_REQUEST_RESET:
      response = {};
      return {
        ...response,
      };

    default:
      return state;
  }
};
