import React, { Component } from "react";
import AuthService from "../../AuthService";
import ServerUtils from "../../api/server";
import { mainNav } from "../../common/acl/acl-admin-template";
import { Button, Input, InputGroup } from "reactstrap";
import { Row, Col } from "react-grid-system";
import Select from "react-select";
import { Label } from "reactstrap";
import "../access-controls/acl.css";
import axios from "axios";
import Textarea from "react-textarea-autosize";
import validation from "../../common/validationConfig";
import { fetchAccountInfo } from "../../data-calls/fetchAccountInfo";

let timeout = 0;

export default class EditAclTemplate extends Component {
  state = {
    aclData: [],
    currentTemplate: "",
    landingPage: "",
    tempName: "",
    tempNotes: "",
    isSaving: false,
    message: {
      status: null,
      text: null,
    },
    isSuperAdmin: false,
  };

  setSuperAdminStatus = (res) => {
    this.setState({ isSuperAdmin: res.isSuperAdmin });
  };

  renderInputs = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <Label>Notes</Label>
        <br />
        <Textarea
          maxLength={validation.textArea}
          style={{ height: 900, width: "30%", padding: 5 }}
          value={this.state.currentTemplate.notes}
          name="notes"
          minRows={3}
          maxRows={10}
          onChange={(e) => {
            this.setNotes(e);
          }}
        />
        <div style={{ height: 20 }}>{this.state.isSaving ? <p style={{ marginLeft: 20 }}>Saving...</p> : null}</div>
      </div>
    );
  };

  setTimeoutBeforeSend = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.updateTemplateInfo();
    }, 1000);
  };

  updateTemplateInfo = async () => {
    const as = new AuthService();
    const URL = ServerUtils.apiBaseUrlProd() + "/auths2/updateTemplate";
    let payload = {
      token: as.getToken(),
      templateID: this.props.match.params.id,
      moduleID: "notes",
      featureName: "notes",
      acl: null,
      templateName: this.state.tempName,
      notes: this.state.currentTemplate.notes,
      subField: "",
    };
    this.setState({ isSaving: true });
    const call = await axios.post(URL, payload).catch((error) => {
      const { message } = this.state;
      message.status = "error";
      message.text = "Something wrong the Server";
      this.setState({ message, isSaving: false });
      return;
    });
    if (call.data.code === -1) {
      const { message } = this.state;
      message.status = "error";
      message.text = call.data.message;
      this.setState({ message });
    }
    this.setState({ isSaving: false });
  };

  setNotes = async (e) => {
    const { currentTemplate } = this.state;
    currentTemplate.notes = e.target.value;
    await this.setState({ currentTemplate });
    this.setTimeoutBeforeSend();
  };

  callToSetParentModule = async (index, type) => {
    const as = new AuthService();
    const URL = ServerUtils.apiBaseUrlProd() + "/auths2/updateTemplate";
    let payload = {
      token: as.getToken(),
      templateID: this.props.match.params.id,
      moduleID: null,
      featureName: this.state.aclData[index].featureName,
      url: this.state.aclData[index].url,
      acl: type,
      notes: this.state.aclData[index].notes,
      subField: "",
    };
    if (type === "AA") {
      payload.acl = "A";
      payload.subField = null;
    }

    let dataCall = await axios.post(URL, payload);
    if (dataCall.data.code === -1) {
      const { message } = this.state;
      message.status = "error";
      message.text = dataCall.data.message;
      this.setState({ message });
      return -1;
    }

    return 0;
  };

  setActive = async (type, index) => {
    const { aclData } = this.state;
    const resData = await this.callToSetParentModule(index, type);

    if (resData === -1) {
      return;
    }
    if (type === "AA") {
      if (aclData[index].tabs) {
        aclData[index].tabs.forEach((item) => {
          item.acl = "A";
        });
      }
    } else if (type === "H") {
      aclData[index].acl = "H";
      if (aclData[index].tabs) {
        aclData[index].tabs.forEach((item) => {
          item.acl = "H";
        });
      }
    } else if (type === "D") {
      if (aclData[index].tabs) {
        aclData[index].tabs.forEach((item) => {
          item.acl = "D";
        });
      }
    } else if (type === "A") {
      if (aclData[index].tabs) {
        const as = new AuthService();
        const URL = ServerUtils.apiBaseUrlProd() + "/auths2/updateTemplate";
        aclData[index].tabs.forEach((item) => {
          let boolean = false;
          if (item.id === "account-settings") {
            item.acl = "A";
            boolean = true;
          } else {
            item.acl = "H";
          }
          const payload = {
            orgID: this.props.orgID,
            featureName: this.state.aclData[index].featureName,
            subField: item.id,
            moduleID: item.id,
            acl: boolean ? "A" : "H",
            url: null,
            templateID: this.props.match.params.id,
            token: as.getToken(),
          };
          axios.post(URL, payload);
        });
      }
    }
    aclData[index].acl = type;
    this.setState({ aclData });
  };

  renderDropDown = (index, index2) => {
    const acl = this.state.aclData[index].tabs[index2].acl;
    let aclValue;
    const actions = [
      { value: "A", label: "Active" },
      { value: "H", label: "Hidden" },
    ];
    const findLabel = actions.filter((item) => item.value === acl);
    if (findLabel.length) {
      aclValue = { value: acl, label: findLabel[0].label };
    } else {
      aclValue = {};
    }

    return (
      <Select
        isDisabled={
          this.state.aclData[index].tabs[index2].id === "account-settings" ||
          this.state.aclData[index].acl === "AA" ||
          this.state.aclData[index].acl === "D"
            ? true
            : false
        }
        options={actions}
        value={aclValue}
        onChange={(e) => this.setSubActive(index, index2, e)}
      />
    );
  };

  setSubActive = async (index, index2, e) => {
    const as = new AuthService();
    const URL = ServerUtils.apiBaseUrlProd() + "/auths2/updateTemplate";

    const payload = {
      featureName: this.state.aclData[index].featureName,
      moduleID: this.state.aclData[index].tabs[index2].id,
      templateID: this.props.match.params.id,
      acl: e.value,
      url: null,
      subField: this.state.aclData[index].tabs[index2].id,
      token: as.getToken(),
    };

    let dataCall = await axios.post(URL, payload);
    if (dataCall.data.code === -1) {
      const { message } = this.state;
      message.status = "error";
      message.text = dataCall.data.message;
      this.setState({ message });
      return;
    }
    const { aclData } = this.state;
    aclData[index].tabs[index2].acl = e.value;
    this.setState({ aclData });
  };

  mapACLData = () => {
    const { aclData, isSuperAdmin } = this.state;

    return aclData.map((item, index) => {
      return (
        <div key={item.url} className="acl--card">
          <p className={item.acl === "H" ? "acl--card__headerOff" : "acl--card__header"}>{item.title}</p>
          <div className="acl--button__col">
            <Button
              onClick={(e) => this.setActive("AA", index)}
              color={item.acl === "AA" ? "primary" : "secondary"}
              className="acl--button"
              disabled={item.superAdminRestricted && !isSuperAdmin}
            >
              All Active
            </Button>
            {item.isCustomButton ? (
              <Button
                onClick={(e) => this.setActive("A", index)}
                color={item.acl === "A" ? "primary" : "secondary"}
                className="acl--button"
              >
                Custom
              </Button>
            ) : null}
            {item.title === "Profile" ? null : (
              <React.Fragment>
                <Button
                  onClick={(e) => this.setActive("H", index)}
                  color={item.acl === "H" ? "danger" : "secondary"}
                  className="acl--button"
                  disabled={item.superAdminRestricted && !isSuperAdmin}
                >
                  Hidden
                </Button>
              </React.Fragment>
            )}
          </div>
          {item.acl === "H" ? null : (
            <div>
              {item.tabs ? (
                <div style={{ marginTop: 0.5 + "em" }} className="acl--sub__container">
                  {item.tabs.length === 0 ? null : <h4>Sub-Modules</h4>}

                  <Col>
                    {item.tabs.map((sub, index2) => {
                      return (
                        <Row key={sub.id}>
                          <div
                            className="acl--sub__row"
                            style={
                              sub.acl === "H"
                                ? {
                                    color: "red",
                                  }
                                : null
                            }
                          >
                            <p className="acl--sub__label">{sub.label}</p>
                          </div>
                          <div className="acl--sub__dropdown">
                            {}
                            {this.renderDropDown(index, index2)}
                          </div>
                        </Row>
                      );
                    })}
                  </Col>
                </div>
              ) : null}
            </div>
          )}
        </div>
      );
    });
  };

  renderLPDropDown = () => {
    const mapOptions = this.state.aclData.map((item) => {
      return {
        value: item.url,
        label: item.title,
      };
    });
    return (
      <Select
        className="acl--selector"
        options={mapOptions}
        value={this.state.landingPage}
        onChange={this.handleLandingPage}
      />
    );
  };

  handleLandingPage = (e) => {
    this.setState({ landingPage: e });
    this.callToChangeLandingPage(e.value);
  };

  callToChangeLandingPage = async (e) => {
    const as = new AuthService();
    const URL = ServerUtils.apiBaseUrlProd() + "/auths2/updateTemplate";
    let payload = {
      token: as.getToken(),
      moduleID: "landingPage",
      featureName: "landingPage",
      templateID: this.props.match.params.id,
      url: e,
      acl: "A",
      subField: "landingPage",
    };

    let dataCall = await axios.post(URL, payload);
    if (!dataCall.data.code) {
      return;
    }
    if (dataCall.data.code === -1) {
      const { message } = this.state;
      message.status = "error";
      message.text = dataCall.data.message;
      this.setState({ message });

      return;
    }
  };

  parseRoute = (acl) => {
    mainNav.landingPage = acl.landingPage;
    mainNav.routes.forEach((item) => {
      acl.routes.forEach((item2) => {
        if (item2.url === item.url) {
          if (!item.tabs) {
            item.isCustomButton = false;
          } else {
            item.isCustomButton = true;
          }
          if (!item2.tabs && item2.acl === "A") {
            item.acl = "AA";
            if (item.tabs) {
              item.tabs.forEach((tab) => {
                tab.acl = "A";
              });
            }
          } else {
            item.acl = item2.acl;
            if (item2.tabs) {
              item2.tabs.forEach((subAcl) => {
                if (item.tabs && item.tabs.length > 0) {
                  item.tabs.forEach((tab) => {
                    if (subAcl.id === tab.id) {
                      tab.acl = subAcl.acl;
                    }
                  });
                }
              });
            }
          }
        }
      });
    });
    return mainNav.routes;
  };

  getLabelForLandingPage = (url) => {
    const filterURL = mainNav.routes.filter((item) => item.url === url);
    return filterURL[0].title;
  };

  getCurrentTemplate = async () => {
    const as = new AuthService();
    const URL = ServerUtils.apiBaseUrlProd() + "/auths2/getOneTemplate";
    let templateID = this.props.match.params.id;
    let payload = { templateID, token: as.getToken() };
    let template = await axios.post(URL, { payload });

    if (template.data.code === -1) {
      const { message } = this.state;
      message.status = "error";
      message.text = template.data.message;
      this.setState({ message });
      return;
    }
    if (template.data.code === 0) {
      await this.setState({
        currentTemplate: template.data.result,
        aclData: this.parseRoute(template.data.result),
        landingPage: {
          value: template.data.result.landingPage,
          label: this.getLabelForLandingPage(template.data.result.landingPage),
        },
      });
    }
  };

  // parseRoute = acl => {
  //   // mainNav.landingPage = acl.landingPage;
  //   mainNav.routes.forEach(item => {
  //     acl.routes.forEach(item2 => {
  //       if (item2.url === item.url) {
  //         if (!item2.tabs && item2.acl === "A") {
  //           item.acl = "AA";
  //           if (item.tabs) {
  //             item.tabs.forEach(tab => {
  //               tab.acl = "A";
  //             });
  //           }
  //         } else {
  //           item.acl = item2.acl;
  //           if(item2.tabs){
  //               item2.tabs.forEach(subAcl => {
  //                   item.tabs.forEach(tab => {
  //                     if (subAcl.id === tab.id) {
  //                       tab.acl = subAcl.acl;
  //                     }
  //                   });
  //                 });
  //           }
  //         }
  //       }
  //     });
  //   });

  //   return mainNav.routes;
  // };

  resetMessageState = () => {
    const { message } = this.state;
    message.status = null;
    message.text = null;
    this.setState({ message });
  };

  componentDidMount = async () => {
    fetchAccountInfo(
      {},
      this.setSuperAdminStatus,
      () => this.setSuperAdminStatus({ isSuperAdmin: false }),
      () => this.setSuperAdminStatus({ isSuperAdmin: false }),
      "Acl",
    );
    await this.getCurrentTemplate();
  };

  render() {
    const { message } = this.state;
    return (
      <div>
        {message.status ? (
          <div
            style={
              message.status === "error"
                ? {
                    padding: 10,
                    backgroundColor: "#F84747",
                    color: "white",
                    textAlign: "center",
                    cursor: "pointer",
                  }
                : {
                    padding: 10,
                    backgroundColor: "#5CB85C",
                    color: "white",
                    textAlign: "center",
                    cursor: "pointer",
                  }
            }
            onClick={() => this.resetMessageState()}
          >
            {message.text}{" "}
          </div>
        ) : null}
        <div className="aclTemplate--mainContainer ">
          <h2 className="acl--heading__landing">{this.state.currentTemplate.templateName}</h2>
          {this.renderInputs()}
          <h4 className="acl--heading__landing">Select Landing Page</h4>
          {this.renderLPDropDown()}
        </div>
        <div className="acl--card__row">{this.mapACLData()}</div>
      </div>
    );
  }
}
